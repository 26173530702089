#slogan{
  font-family: "Dancing Script", "Source Sans Pro", "Arial", sans-serif;;
  font-size: 50pt;
}

.presentation{
  @media (min-width: 992px) {
    border-right: 1px solid #9e9e9e;
  }
}
.presentation-secondary{
  @media (max-width: 992px) {
    margin-left: 16.666667%;
    margin-top: 10px;
  }
}



.presentation_title{
  //font-family: "Dancing Script", "Source Sans Pro", "Arial", sans-serif;;
  font-size: 18pt;
  //font-weight: bold;
}

.presentation_content{
  //font-family: "Dancing Script", "Source Sans Pro", "Arial", sans-serif;;
  font-size: 12pt;
  text-align: justify;
}

.presentation_service_item{
  $background: #FFFFFF;

  background-color: $background;
  //background-color: darken($background, 5%);

  border: 1px solid black;
  //border-radius: 10px;
  margin-bottom:5px;
  padding-top: 5px;
  padding-bottom: 5px;

  &>div{
    text-align: center;
  }

  &:hover{
    //background-color: darken($background, 5%);
    font-weight: bold;
  }
}

.presentation_marques{
  font-family: "Indie Flower", "Source Sans Pro", "Arial", sans-serif;
  font-size: 14pt;

  &_img_container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
  }

  &_img{
    width: 150px;
    margin-left: 30px;
    margin-right: 30px;
    object-fit: contain;
  }
}